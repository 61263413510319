body {
  margin: 0;
}

.submit-product {
  margin: 1rem 25% !important;
  width: 100%;
}

.id-selected {
  background-color: #e5e6e7 !important;
}
